define("ember-torusoft-signin/components/forms/parts/bodies/password-reset", ["exports", "@glimmer/component", "@ember/service", "@ember/object", "@glimmer/tracking", "ember-torusoft-signin/utils/user-password-reset"], function (_exports, _component, _service, _object, _tracking, _userPasswordReset) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class, _descriptor, _descriptor2, _descriptor3;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    {{#if (has-block)}}
    {{yield this}}
  {{else}}
    <div class="form-group">
        <label for="password">{{this.passwordLabel}}</label>
        <Input class="form-control" @id="password" @required={{true}} type="password" @value={{this.password}} @autofocus="true" />
    </div>
    <div class="form-group">
        <label for="confirm-password">{{this.confirmPasswordLabel}}</label>
        <Input class="form-control" @id="confirm-password" @required={{true}} type="password" @value={{this.confirmedPassword}} @autofocus="true" />
    </div>
  
    <button disabled={{not this.isInputDataValid}} {{on 'click' this.onClickPasswordReset}} type="button" class="w-100 btn btn-success">{{this.submitPasswordResetButtonLabel}}</button>
  {{/if}}
  */
  {
    id: "yY9yhNKO",
    block: "[[[41,[48,[30,1]],[[[1,\"  \"],[18,1,[[30,0]]],[1,\"\\n\"]],[]],[[[1,\"  \"],[10,0],[14,0,\"form-group\"],[12],[1,\"\\n      \"],[10,\"label\"],[14,\"for\",\"password\"],[12],[1,[30,0,[\"passwordLabel\"]]],[13],[1,\"\\n      \"],[8,[39,3],[[24,0,\"form-control\"],[24,4,\"password\"]],[[\"@id\",\"@required\",\"@value\",\"@autofocus\"],[\"password\",true,[30,0,[\"password\"]],\"true\"]],null],[1,\"\\n  \"],[13],[1,\"\\n  \"],[10,0],[14,0,\"form-group\"],[12],[1,\"\\n      \"],[10,\"label\"],[14,\"for\",\"confirm-password\"],[12],[1,[30,0,[\"confirmPasswordLabel\"]]],[13],[1,\"\\n      \"],[8,[39,3],[[24,0,\"form-control\"],[24,4,\"password\"]],[[\"@id\",\"@required\",\"@value\",\"@autofocus\"],[\"confirm-password\",true,[30,0,[\"confirmedPassword\"]],\"true\"]],null],[1,\"\\n  \"],[13],[1,\"\\n\\n  \"],[11,\"button\"],[16,\"disabled\",[28,[37,4],[[30,0,[\"isInputDataValid\"]]],null]],[24,0,\"w-100 btn btn-success\"],[24,4,\"button\"],[4,[38,5],[\"click\",[30,0,[\"onClickPasswordReset\"]]],null],[12],[1,[30,0,[\"submitPasswordResetButtonLabel\"]]],[13],[1,\"\\n\"]],[]]]],[\"&default\"],false,[\"if\",\"has-block\",\"yield\",\"input\",\"not\",\"on\"]]",
    moduleName: "ember-torusoft-signin/components/forms/parts/bodies/password-reset.hbs",
    isStrictMode: false
  });

  let FormsPartsBodiesPasswordResetComponent = (_class = class FormsPartsBodiesPasswordResetComponent extends _component.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "signin", _descriptor, this);

      _initializerDefineProperty(this, "password", _descriptor2, this);

      _initializerDefineProperty(this, "confirmedPassword", _descriptor3, this);
    }

    get isInputDataValid() {
      switch (true) {
        case this.args.accountIdentifier == null:
        case this.args.accountIdentifier == undefined:
        case this.args.accountIdentifier == "":
        case this.password != this.confirmedPassword:
        case this.password == "":
        case this.password == null:
        case this.password == undefined:
          return false;
      }

      return true;
    }

    get passwordLabel() {
      return this.args.passwordLabel ? this.args.passwordLabel : this.signin.passwordResetPasswordInputLabel;
    }

    get confirmPasswordLabel() {
      return this.args.confirmPasswordLabel ? this.args.confirmPasswordLabel : this.signin.passwordResetConfirmPasswordInputLabel;
    }

    get submitPasswordResetButtonLabel() {
      return this.args.submitPasswordResetButtonLabel ? this.args.submitPasswordResetButtonLabel : this.signin.passwordResetSubmitPasswordResetButtonLabel;
    }

    get requestOptions() {
      return this.args.passwordResetRequestOptions ? this.args.passwordResetRequestOptions : {
        headers: this.signin.requestHeaders
      };
    }

    async onClickPasswordReset(submitEvent) {
      submitEvent.preventDefault();

      if (this.allowMultipleSubmissions === false && this.hasSubmittedRequest === true) {
        return;
      }

      let hookResponse = true;
      this.hasSubmittedRequest = true;

      if (this.args.onBeforeClickPasswordReset) {
        hookResponse = await this.args.onBeforeClickPasswordReset(submitEvent, this);
      }

      if (hookResponse && this.args.onClickPasswordReset) {
        hookResponse = await this.args.onClickPasswordReset(hookResponse, this.args.accountIdentifier, this.password, this.confirmedPassword, submitEvent, this);
      } else {
        hookResponse = await this._onClickPasswordReset(hookResponse, submitEvent);
      }

      if (hookResponse && this.args.onAfterClickPasswordReset) {
        hookResponse = await this.args.onAfterClickPasswordReset(hookResponse, this.userIdentifier, submitEvent, this);
      }

      return hookResponse;
    }

    async _onClickPasswordReset(hookResponse, submitEvent) {
      submitEvent.srcElement.disabled = true; // specify both id and email for the use case of having a login system
      // where the account id is not an email address

      const postData = {
        id: this.args.accountIdentifier,
        email: this.args.accountIdentifier,
        token: this.args.resetToken,
        password: this.password,
        confirmedPassword: this.confirmedPassword
      };

      if (this.args.forceDelay) {
        await new Promise(resolve => setTimeout(() => resolve(), this.args.forceDelay));
      }

      try {
        if (this.args.debugResponseSuccess) {
          return {
            'message': 'DEBUG: SIMULATING SUCCESSFUL SERVER RESPONSE'
          };
        } else if (this.args.debugResponseFailure) {
          return {
            'errors': [{
              detail: 'DEBUG: SIMULATING FAILED SERVER RESPONSE'
            }]
          };
        } else {
          return await (0, _userPasswordReset.resetPassword)(this, postData, this.requestOptions);
        }
      } catch (errorObject) {
        return errorObject;
      } finally {
        if (this.allowMultipleSubmissions === true || this.hasSubmittedRequest !== true) {
          setTimeout(() => {
            submitEvent.srcElement.disabled = false;
          }, 2000);
        }
      }
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "signin", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "password", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return "";
    }
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "confirmedPassword", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return "";
    }
  }), _applyDecoratedDescriptor(_class.prototype, "onClickPasswordReset", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "onClickPasswordReset"), _class.prototype)), _class);
  _exports.default = FormsPartsBodiesPasswordResetComponent;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, FormsPartsBodiesPasswordResetComponent);
});