define("ember-torusoft-signin/instance-initializers/ember-torusoft-signin", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.activate = activate;
  _exports.default = void 0;
  _exports.forgotPassword = forgotPassword;
  _exports.initialize = initialize;
  _exports.registerRoute = registerRoute;
  _exports.resetPassword = resetPassword;
  _exports.signIn = signIn;
  const routingFunctions = [signIn, forgotPassword, resetPassword, activate];

  function initialize(appInstance, routes) {
    if (!routes) {
      routes = routingFunctions;
    }

    let routeRegistrations = routes.concat(() => console.debug('DEBUG: -------------------------------'));
    routeRegistrations.forEach(fn => fn(appInstance));
  }

  var _default = {
    initialize,
    activate,
    signIn,
    forgotPassword,
    resetPassword
  };
  _exports.default = _default;

  function signIn(appInstance) {
    registerRoute(appInstance, 'activate');
  }

  function activate(appInstance) {
    registerRoute(appInstance, 'sign-in');
  }

  function forgotPassword(appInstance) {
    registerRoute(appInstance, 'forgot-password');
  }

  function resetPassword(appInstance) {
    registerRoute(appInstance, 'reset-password');
  }

  function registerRoute(appInstance, routeName, options, nestedRouteFunction) {
    let routerFactoryManager = appInstance.factoryFor('router:main');
    let router = routerFactoryManager.class;
    console.debug(`DEBUG: Ember Torusoft Sign-in is registering the route: '${routeName}'`);
    router.map(function () {
      this.route(routeName, options, nestedRouteFunction);
    });
  }
});