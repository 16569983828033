define("ember-torusoft-signin/components/forms/parts/server-responses/default", ["exports", "@glimmer/component", "@ember/service"], function (_exports, _component, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class, _descriptor;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    {{#if (has-block)}}
    {{yield this}}
  {{else}}
    {{#if (eq @requestStatus false)}}
      <div class="text-center pt-2">
        <p><FaIcon @icon="exclamation-triangle" class="text-danger mr-2"/> {{this.errorMessage}}</p>
      </div>
    {{else if (eq @requestStatus true)}}
      <div class="text-center pt-2">
        <p><FaIcon @icon="thumbs-up" class="text-success mr-2"/>{{this.successMessage}}</p>
      </div>
    {{/if}}
  {{/if}}
  */
  {
    id: "n0Yb3e+3",
    block: "[[[41,[48,[30,2]],[[[1,\"  \"],[18,2,[[30,0]]],[1,\"\\n\"]],[]],[[[41,[28,[37,3],[[30,1],false],null],[[[1,\"    \"],[10,0],[14,0,\"text-center pt-2\"],[12],[1,\"\\n      \"],[10,2],[12],[8,[39,4],[[24,0,\"text-danger mr-2\"]],[[\"@icon\"],[\"exclamation-triangle\"]],null],[1,\" \"],[1,[30,0,[\"errorMessage\"]]],[13],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]],[[[41,[28,[37,3],[[30,1],true],null],[[[1,\"    \"],[10,0],[14,0,\"text-center pt-2\"],[12],[1,\"\\n      \"],[10,2],[12],[8,[39,4],[[24,0,\"text-success mr-2\"]],[[\"@icon\"],[\"thumbs-up\"]],null],[1,[30,0,[\"successMessage\"]]],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"]],[]],null]],[]]]],[]]]],[\"@requestStatus\",\"&default\"],false,[\"if\",\"has-block\",\"yield\",\"eq\",\"fa-icon\"]]",
    moduleName: "ember-torusoft-signin/components/forms/parts/server-responses/default.hbs",
    isStrictMode: false
  });

  let FormsPartsServerResponsesDefaultComponent = (_class = class FormsPartsServerResponsesDefaultComponent extends _component.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "signin", _descriptor, this);
    }

    get errorMessage() {
      return this.args.message ? this.args.message : this.signin.forgotPasswordRequestErrorMessage;
    }

    get successMessage() {
      return this.args.message ? this.args.message : this.signin.forgotPasswordRequestSuccessMessage;
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "signin", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class);
  _exports.default = FormsPartsServerResponsesDefaultComponent;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, FormsPartsServerResponsesDefaultComponent);
});