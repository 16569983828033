define("ember-pouch/transforms/attachments", ["exports", "@ember/array", "@ember/polyfills", "@ember/object", "@ember/utils", "ember-data"], function (_exports, _array, _polyfills, _object, _utils, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const keys = Object.keys || _polyfills.keys;

  var _default = _emberData.default.Transform.extend({
    deserialize: function (serialized) {
      if ((0, _utils.isNone)(serialized)) {
        return [];
      }

      return keys(serialized).map(function (attachmentName) {
        let attachment = serialized[attachmentName];
        return _object.default.create({
          name: attachmentName,
          content_type: attachment.content_type,
          data: attachment.data,
          stub: attachment.stub,
          length: attachment.length,
          digest: attachment.digest
        });
      });
    },
    serialize: function (deserialized) {
      if (!(0, _array.isArray)(deserialized)) {
        return null;
      }

      return deserialized.reduce(function (acc, attachment) {
        const serialized = {
          content_type: (0, _object.get)(attachment, 'content_type')
        };

        if ((0, _object.get)(attachment, 'stub')) {
          serialized.stub = true;
          serialized.length = (0, _object.get)(attachment, 'length');
          serialized.digest = (0, _object.get)(attachment, 'digest');
        } else {
          serialized.data = (0, _object.get)(attachment, 'data');
          serialized.length = (0, _object.get)(attachment, 'length');
        }

        acc[(0, _object.get)(attachment, 'name')] = serialized;
        return acc;
      }, {});
    }
  });

  _exports.default = _default;
});