define("ember-torusoft-signin/utils/user-password-reset", ["exports", "@ember/application", "fetch"], function (_exports, _application, _fetch) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.requestPasswordReset = requestPasswordReset;
  _exports.resetPassword = resetPassword;

  // Initiates an account password request.
  //
  // options:
  //  modelName: Singular entity name for users used for api namespacing.  Default 'user'
  //  headers: Headers to send with request. Default {'Content-Type': 'application/json'}.
  //  endpoint: activation endpoint name. Default 'reset-password'
  //
  //  Notes:
  //    1. Full api endpoint is built using the following format:
  //      ENV.apiServer.host/ENV.apiServer.namespace/pluralizedModelName/data.id/options.endpoint
  //
  // Example:
  // const data = {
  //   id: 'lee@torusoft.com',
  //   email: 'lee@torusoft.com',
  // };
  //
  // const options = {
  //   modelName: 'user',
  //   headers: {'Content-Type': 'application/json'},
  //   endpoint: 'activate'
  // }
  //
  // requestPasswordReset(this, data, options)
  async function requestPasswordReset(context, data, options) {
    options = options || {};
    const expectedAttributes = ['id', 'email']; // const modelName = 'modelName' in options ? options.modelName : 'user';
    // const pluralizedModelName = pluralize(modelName);

    const config = (0, _application.getOwner)(context).resolveRegistration('config:environment');
    const headers = options.headers || {
      'Content-Type': 'application/json'
    };
    const endpointName = options.endpoint || 'i/accounts/request-password-reset';
    const url = `${config.apiServer.host}/${config.apiServer.namespace}/${endpointName}`;

    if (!config) {
      throw TypeError(`The environment configuration couldn't be resolved`);
    } else if (!('apiServer' in config)) {
      throw TypeError(`The environment configuration does not have an 'apiServer' attribute;`);
    } // validate args


    expectedAttributes.map(attribute => {
      if (!(attribute in data)) {
        throw TypeError(`'${attribute}' is a required key in the second argument to this function.`);
      }
    });

    try {
      const fetch_result = await (await (0, _fetch.default)(url, {
        method: 'POST',
        headers: headers,
        credentials: 'include',
        body: JSON.stringify({
          email: data.email,
          id: data.id
        })
      })).json();
      return 'ok' in fetch_result && fetch_result.ok === true ? Promise.resolve(fetch_result) : Promise.reject(fetch_result);
    } catch (e) {
      return {
        'errors': [{
          detail: 'The server could not be contacted. Check your internet connection and try again.'
        }]
      };
    }
  } // Complete a password reset request.
  //
  // options:
  //  modelName: Singular entity name for users used for api namespacing.  Default 'user'
  //  headers: Headers to send with request. Default {'Content-Type': 'application/json'}.
  //  endpoint: activation endpoint name. Default 'reset-password'
  //
  //  Notes:
  //    1. Full api endpoint is built using the following format:
  //      ENV.apiServer.host/ENV.apiServer.namespace/pluralizedModelName/data.id/options.endpoint
  //
  // Example:
  // const data = {
  //   id: 'lee@torusoft.com',
  //   email: 'lee@torusoft.com',
  //   token: '6f412d2042a3f940',
  //   password: 'letmein',
  //   confirmedPassword: 'letmein'
  // };
  //
  // const options = {
  //   modelName: 'user',
  //   headers: {'Content-Type': 'application/json'},
  //   endpoint: 'activate'
  // }
  //
  // requestPasswordReset(this, data, options)


  async function resetPassword(context, data, options) {
    options = options || {};
    const expectedAttributes = ['id', 'email'];
    const config = (0, _application.getOwner)(context).resolveRegistration('config:environment');
    const headers = options.headers || {
      'Content-Type': 'application/json'
    };
    const endpointName = options.endpoint || 'i/accounts/password-reset';
    const url = `${config.apiServer.host}/${config.apiServer.namespace}/${endpointName}`;

    if (!config) {
      throw TypeError(`The environment configuration couldn't be resolved`);
    } else if (!('apiServer' in config)) {
      throw TypeError(`The environment configuration does not have an 'apiServer' attribute;`);
    } // validate args


    expectedAttributes.map(attribute => {
      if (!(attribute in data)) {
        throw TypeError(`'${attribute}' is a required key in the second argument to this function.`);
      }
    });

    try {
      const fetch_result = await (await (0, _fetch.default)(url, {
        method: 'POST',
        headers: headers,
        credentials: 'include',
        body: JSON.stringify({
          email: data.email,
          id: data.id,
          resetToken: data.token,
          password: data.password,
          confirmedPassword: data.confirmedPassword
        })
      })).json();
      return 'ok' in fetch_result && fetch_result.ok === true ? Promise.resolve(fetch_result) : Promise.reject(fetch_result);
    } catch (e) {
      return {
        'errors': [{
          detail: 'The server could not be contacted. Check your internet connection and try again.'
        }]
      };
    }
  }

  var _default = {
    requestPasswordReset,
    resetPassword
  };
  _exports.default = _default;
});