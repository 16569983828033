define("ember-pouch/pouchdb", ["exports", "pouchdb-browser", "pouchdb-find", "relational-pouch"], function (_exports, _pouchdbBrowser, _pouchdbFind, _relationalPouch) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  _pouchdbBrowser.default.plugin(_pouchdbFind.default).plugin(_relationalPouch.default);

  var _default = _pouchdbBrowser.default;
  _exports.default = _default;
});