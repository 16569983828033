define("ember-torusoft-api-adapter/transforms/attachment", ["exports", "ember-torusoft-api-adapter/transforms/attachments", "@ember/utils"], function (_exports, _attachments, _utils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // Sourced from https://github.com/pouchdb-community/ember-pouch
  class AttachmentTransform extends _attachments.default {
    deserialize(serialized) {
      return super.deserialize(serialized).pop();
    }

    serialize(deserialized) {
      if ((0, _utils.isNone)(deserialized)) {
        return null;
      }

      return super.serialize([deserialized]);
    }

  }

  _exports.default = AttachmentTransform;
});