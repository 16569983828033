define("ember-torusoft-signin/services/signin", ["exports", "@ember/service", "@glimmer/tracking"], function (_exports, _service, _tracking) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12, _descriptor13, _descriptor14, _descriptor15, _descriptor16, _descriptor17, _descriptor18, _descriptor19, _descriptor20, _descriptor21, _descriptor22, _descriptor23, _descriptor24, _descriptor25, _descriptor26, _descriptor27, _descriptor28, _descriptor29, _descriptor30, _descriptor31, _descriptor32, _descriptor33, _descriptor34, _descriptor35, _descriptor36, _descriptor37, _descriptor38;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let SigninService = (_class = class SigninService extends _service.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "store", _descriptor, this);

      _initializerDefineProperty(this, "requestHeaders", _descriptor2, this);

      _initializerDefineProperty(this, "loginHeaderText", _descriptor3, this);

      _initializerDefineProperty(this, "loginGenericErrorMessage", _descriptor4, this);

      _initializerDefineProperty(this, "forgotPasswordHeaderText", _descriptor5, this);

      _initializerDefineProperty(this, "forgotPasswordFooterSigninRouteText", _descriptor6, this);

      _initializerDefineProperty(this, "forgotPasswordUsernameInputLabel", _descriptor7, this);

      _initializerDefineProperty(this, "forgotPasswordUsernameInputType", _descriptor8, this);

      _initializerDefineProperty(this, "forgotPasswordSubmitPasswordResetButtonLabel", _descriptor9, this);

      _initializerDefineProperty(this, "forgotPasswordRequestErrorMessage", _descriptor10, this);

      _initializerDefineProperty(this, "forgotPasswordRequestSuccessMessage", _descriptor11, this);

      _initializerDefineProperty(this, "forgotPasswordRequestProcessingMessage", _descriptor12, this);

      _initializerDefineProperty(this, "forgotPasswordRequestSpinnerIcon", _descriptor13, this);

      _initializerDefineProperty(this, "passwordResetHeaderText", _descriptor14, this);

      _initializerDefineProperty(this, "passwordResetFooterSigninRouteText", _descriptor15, this);

      _initializerDefineProperty(this, "passwordResetPasswordInputLabel", _descriptor16, this);

      _initializerDefineProperty(this, "passwordResetConfirmPasswordInputLabel", _descriptor17, this);

      _initializerDefineProperty(this, "passwordResetSubmitPasswordResetButtonLabel", _descriptor18, this);

      _initializerDefineProperty(this, "passwordResetRequestProcessingMessage", _descriptor19, this);

      _initializerDefineProperty(this, "passwordResetRequestSpinnerIcon", _descriptor20, this);

      _initializerDefineProperty(this, "passwordResetRequestErrorMessage", _descriptor21, this);

      _initializerDefineProperty(this, "passwordResetRequestSuccessMessage", _descriptor22, this);

      _initializerDefineProperty(this, "activateAccountHeaderText", _descriptor23, this);

      _initializerDefineProperty(this, "activateAccountFooterSigninRouteText", _descriptor24, this);

      _initializerDefineProperty(this, "activateAccountFooterSigninRouteText", _descriptor25, this);

      _initializerDefineProperty(this, "activateAccountSubmitActivateAccountButtonLabel", _descriptor26, this);

      _initializerDefineProperty(this, "activateAccountUsernameInputLabel", _descriptor27, this);

      _initializerDefineProperty(this, "activateAccountUsernameInputType", _descriptor28, this);

      _initializerDefineProperty(this, "activateAccountSpinnerIcon", _descriptor29, this);

      _initializerDefineProperty(this, "activateAccountProcessingMessage", _descriptor30, this);

      _initializerDefineProperty(this, "activateAccountPasswordInputLabel", _descriptor31, this);

      _initializerDefineProperty(this, "activateAccountConfirmPasswordInputLabel", _descriptor32, this);

      _initializerDefineProperty(this, "loginProcessingMessage", _descriptor33, this);

      _initializerDefineProperty(this, "loginSpinnerIcon", _descriptor34, this);

      _initializerDefineProperty(this, "loginButtonLabel", _descriptor35, this);

      _initializerDefineProperty(this, "loginFooterForgotPasswordRouteText", _descriptor36, this);

      _initializerDefineProperty(this, "routeForForgotPassword", _descriptor37, this);

      _initializerDefineProperty(this, "routeForSignin", _descriptor38, this);
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "store", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "requestHeaders", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return this.store.adapterFor('application').headers;
    }
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "loginHeaderText", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return "Login to the Administration Portal";
    }
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "loginGenericErrorMessage", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return "<p>There was a problem logging you in.</p><p>Please check your username and password, and verify that you have been granted access to this application.</p>";
    }
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "forgotPasswordHeaderText", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return "Password Reset Request";
    }
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "forgotPasswordFooterSigninRouteText", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return "Take me back to sign-in";
    }
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "forgotPasswordUsernameInputLabel", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return "Email Address";
    }
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "forgotPasswordUsernameInputType", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return "email";
    }
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "forgotPasswordSubmitPasswordResetButtonLabel", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return "Reset my password";
    }
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "forgotPasswordRequestErrorMessage", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return "There was a problem with your request. Please check that account exists.";
    }
  }), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "forgotPasswordRequestSuccessMessage", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return "Password reset request sent! Please check your email for further instructions.";
    }
  }), _descriptor12 = _applyDecoratedDescriptor(_class.prototype, "forgotPasswordRequestProcessingMessage", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return "Requesting password reset, please wait...";
    }
  }), _descriptor13 = _applyDecoratedDescriptor(_class.prototype, "forgotPasswordRequestSpinnerIcon", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return "spinner";
    }
  }), _descriptor14 = _applyDecoratedDescriptor(_class.prototype, "passwordResetHeaderText", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return "Password Reset";
    }
  }), _descriptor15 = _applyDecoratedDescriptor(_class.prototype, "passwordResetFooterSigninRouteText", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return "Take me to sign-in";
    }
  }), _descriptor16 = _applyDecoratedDescriptor(_class.prototype, "passwordResetPasswordInputLabel", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return "Password";
    }
  }), _descriptor17 = _applyDecoratedDescriptor(_class.prototype, "passwordResetConfirmPasswordInputLabel", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return "Confirm Password";
    }
  }), _descriptor18 = _applyDecoratedDescriptor(_class.prototype, "passwordResetSubmitPasswordResetButtonLabel", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return "Reset my password";
    }
  }), _descriptor19 = _applyDecoratedDescriptor(_class.prototype, "passwordResetRequestProcessingMessage", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return "Requesting password reset, please wait...";
    }
  }), _descriptor20 = _applyDecoratedDescriptor(_class.prototype, "passwordResetRequestSpinnerIcon", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return "spinner";
    }
  }), _descriptor21 = _applyDecoratedDescriptor(_class.prototype, "passwordResetRequestErrorMessage", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return "There was a problem with your request. Please try again later.";
    }
  }), _descriptor22 = _applyDecoratedDescriptor(_class.prototype, "passwordResetRequestSuccessMessage", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return "Your password has been changed.";
    }
  }), _descriptor23 = _applyDecoratedDescriptor(_class.prototype, "activateAccountHeaderText", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return "Account Activation";
    }
  }), _descriptor24 = _applyDecoratedDescriptor(_class.prototype, "activateAccountFooterSigninRouteText", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return "Take me to sign-in";
    }
  }), _descriptor25 = _applyDecoratedDescriptor(_class.prototype, "activateAccountFooterSigninRouteText", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return "Take me to sign-in";
    }
  }), _descriptor26 = _applyDecoratedDescriptor(_class.prototype, "activateAccountSubmitActivateAccountButtonLabel", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return "Activate my account";
    }
  }), _descriptor27 = _applyDecoratedDescriptor(_class.prototype, "activateAccountUsernameInputLabel", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return "Email Address";
    }
  }), _descriptor28 = _applyDecoratedDescriptor(_class.prototype, "activateAccountUsernameInputType", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return "email";
    }
  }), _descriptor29 = _applyDecoratedDescriptor(_class.prototype, "activateAccountSpinnerIcon", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return "spinner";
    }
  }), _descriptor30 = _applyDecoratedDescriptor(_class.prototype, "activateAccountProcessingMessage", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return "Activating your account, please wait...";
    }
  }), _descriptor31 = _applyDecoratedDescriptor(_class.prototype, "activateAccountPasswordInputLabel", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return "Password";
    }
  }), _descriptor32 = _applyDecoratedDescriptor(_class.prototype, "activateAccountConfirmPasswordInputLabel", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return "Confirm Password";
    }
  }), _descriptor33 = _applyDecoratedDescriptor(_class.prototype, "loginProcessingMessage", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return "Attempting to authenticate...";
    }
  }), _descriptor34 = _applyDecoratedDescriptor(_class.prototype, "loginSpinnerIcon", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return "spinner";
    }
  }), _descriptor35 = _applyDecoratedDescriptor(_class.prototype, "loginButtonLabel", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return "Sign In";
    }
  }), _descriptor36 = _applyDecoratedDescriptor(_class.prototype, "loginFooterForgotPasswordRouteText", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return "Forgot your password?";
    }
  }), _descriptor37 = _applyDecoratedDescriptor(_class.prototype, "routeForForgotPassword", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return "forgot-password";
    }
  }), _descriptor38 = _applyDecoratedDescriptor(_class.prototype, "routeForSignin", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return "sign-in";
    }
  })), _class);
  _exports.default = SigninService;
});