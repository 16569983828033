define("ember-torusoft-signin/components/forms/login", ["exports", "@glimmer/component", "@ember/object", "@glimmer/tracking", "@ember/service"], function (_exports, _component, _object, _tracking, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class, _descriptor, _descriptor2, _descriptor3, _descriptor4;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    {{#let (hash
      header=(component "forms/parts/headers/login")
      form=(component "forms/parts/bodies/login")
      serverResponse=(component "forms/parts/server-responses/default")
      footer=(component "forms/parts/footers/login")
  ) as |Login|}}
    {{#if (has-block)}}
      {{yield Login}}
    {{else}}
      {{#if this.showHeader}}
        <Login.header/>
      {{/if}}
  
      <Login.form
        @onBeforeClickLogin={{this.onBeforeClickLoginHandler}}
        @onAfterClickLogin={{this.onAfterClickLoginHandler}}
        @loginOptions={{@loginOptions}}
        @accountIdentifier={{@accountIdentifier}}
        @forceDelay={{500}}
        @debugResponseSuccess={{@debugResponseSuccess}}
        @debugResponseFailure={{@debugResponseFailure}}
        @skipPasswordValidation={{@skipPasswordValidation}}
      />
  
      {{#if this.showServerResponse}}
        <Login.serverResponse
          @requestStatus={{this.hasRequestSucceeded}}
          @message={{this.responseMessage}}
        />
      {{/if}}
  
      {{#if (and this.isProcessingRequest this.showRequestProcessing)}}
        <div class="text-center pt-2">
          <p>
            {{#if this.showProcessingSpinner}}
              <FaIcon @icon={{this.processingSpinner}} @spin={{true}} class="text-success"/>
            {{/if}}
            {{this.processingMessage}}
          </p>
        </div>
      {{/if}}
  
      {{#if this.showFooter}}
        <Login.footer/>
      {{/if}}
    {{/if}}
  {{/let}}
  */
  {
    id: "X+edTSGY",
    block: "[[[44,[[28,[37,1],null,[[\"header\",\"form\",\"serverResponse\",\"footer\"],[[50,\"forms/parts/headers/login\",0,null,null],[50,\"forms/parts/bodies/login\",0,null,null],[50,\"forms/parts/server-responses/default\",0,null,null],[50,\"forms/parts/footers/login\",0,null,null]]]]],[[[41,[48,[30,7]],[[[1,\"    \"],[18,7,[[30,1]]],[1,\"\\n\"]],[]],[[[41,[30,0,[\"showHeader\"]],[[[1,\"      \"],[8,[30,1,[\"header\"]],null,null,null],[1,\"\\n\"]],[]],null],[1,\"\\n    \"],[8,[30,1,[\"form\"]],null,[[\"@onBeforeClickLogin\",\"@onAfterClickLogin\",\"@loginOptions\",\"@accountIdentifier\",\"@forceDelay\",\"@debugResponseSuccess\",\"@debugResponseFailure\",\"@skipPasswordValidation\"],[[30,0,[\"onBeforeClickLoginHandler\"]],[30,0,[\"onAfterClickLoginHandler\"]],[30,2],[30,3],500,[30,4],[30,5],[30,6]]],null],[1,\"\\n\\n\"],[41,[30,0,[\"showServerResponse\"]],[[[1,\"      \"],[8,[30,1,[\"serverResponse\"]],null,[[\"@requestStatus\",\"@message\"],[[30,0,[\"hasRequestSucceeded\"]],[30,0,[\"responseMessage\"]]]],null],[1,\"\\n\"]],[]],null],[1,\"\\n\"],[41,[28,[37,6],[[30,0,[\"isProcessingRequest\"]],[30,0,[\"showRequestProcessing\"]]],null],[[[1,\"      \"],[10,0],[14,0,\"text-center pt-2\"],[12],[1,\"\\n        \"],[10,2],[12],[1,\"\\n\"],[41,[30,0,[\"showProcessingSpinner\"]],[[[1,\"            \"],[8,[39,7],[[24,0,\"text-success\"]],[[\"@icon\",\"@spin\"],[[30,0,[\"processingSpinner\"]],true]],null],[1,\"\\n\"]],[]],null],[1,\"          \"],[1,[30,0,[\"processingMessage\"]]],[1,\"\\n        \"],[13],[1,\"\\n      \"],[13],[1,\"\\n\"]],[]],null],[1,\"\\n\"],[41,[30,0,[\"showFooter\"]],[[[1,\"      \"],[8,[30,1,[\"footer\"]],null,null,null],[1,\"\\n\"]],[]],null]],[]]]],[1]]]],[\"Login\",\"@loginOptions\",\"@accountIdentifier\",\"@debugResponseSuccess\",\"@debugResponseFailure\",\"@skipPasswordValidation\",\"&default\"],false,[\"let\",\"hash\",\"component\",\"if\",\"has-block\",\"yield\",\"and\",\"fa-icon\"]]",
    moduleName: "ember-torusoft-signin/components/forms/login.hbs",
    isStrictMode: false
  });

  let FormsLoginComponent = (_class = class FormsLoginComponent extends _component.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "signin", _descriptor, this);

      _initializerDefineProperty(this, "isProcessingRequest", _descriptor2, this);

      _initializerDefineProperty(this, "hasRequestSucceeded", _descriptor3, this);

      _initializerDefineProperty(this, "_responseMessage", _descriptor4, this);
    }

    get showHeader() {
      return this.args.showHeader === true || this.args.showHeader === undefined || this.args.showHeader === null;
    }

    get showFooter() {
      return this.args.showFooter === true || this.args.showFooter === undefined || this.args.showFooter === null;
    }

    get showServerResponse() {
      return this.args.showServerResponse === true || this.args.showServerResponse === undefined || this.args.showServerResponse === null;
    }

    get showServerMessage() {
      return this.args.showServerMessage === true || this.args.showServerMessage === undefined || this.args.showServerMessage === null;
    }

    get showRequestProcessing() {
      return this.args.showRequestProcessing === true || this.args.showRequestProcessing === undefined || this.args.showRequestProcessing === null;
    }

    get showProcessingSpinner() {
      return this.args.showProcessingSpinner === true || this.args.showProcessingSpinner === undefined || this.args.processingSpinner === null;
    }

    get processingMessage() {
      return this.args.processingMessage ? this.args.processingMessage : this.signin.loginProcessingMessage;
    }

    get processingSpinner() {
      return this.args.processingSpinner ? this.args.processingSpinner : this.signin.loginSpinnerIcon;
    }

    get responseMessage() {
      return this.showServerMessage ? this._responseMessage : null;
    }

    onBeforeClickLoginHandler() {
      this.isProcessingRequest = true;
      this.hasRequestSucceeded = null;
      return true;
    }

    onAfterClickLoginHandler(hookResponse, params) {
      console.log(hookResponse);

      if ('errors' in hookResponse) {
        this.hasRequestSucceeded = false;

        if (this.showServerMessage === true) {
          this._responseMessage = hookResponse['errors'][0].detail;
        }
      } else {
        if (this.showServerMessage === true && 'message' in hookResponse) {
          this._responseMessage = hookResponse.message;
        }

        if ('ok' in hookResponse && hookResponse.ok === true) {
          this.hasRequestSucceeded = true;
        }
      }

      this.isProcessingRequest = false;

      if (this.hasRequestSucceeded && this.args.onLoginSuccess) {
        this.args.onLoginSuccess(hookResponse, params);
      }

      return true;
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "signin", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "isProcessingRequest", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "hasRequestSucceeded", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "_responseMessage", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "onBeforeClickLoginHandler", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "onBeforeClickLoginHandler"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onAfterClickLoginHandler", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "onAfterClickLoginHandler"), _class.prototype)), _class);
  _exports.default = FormsLoginComponent;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, FormsLoginComponent);
});