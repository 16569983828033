define("ember-torusoft-api-adapter/index", ["exports", "ember-torusoft-api-adapter/adapters/torusoft-api-adapter", "ember-torusoft-api-adapter/models/torusoft-base"], function (_exports, _torusoftApiAdapter, _torusoftBase) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "TorusoftApiAdapter", {
    enumerable: true,
    get: function () {
      return _torusoftApiAdapter.default;
    }
  });
  Object.defineProperty(_exports, "TorusoftBaseModel", {
    enumerable: true,
    get: function () {
      return _torusoftBase.default;
    }
  });
  _exports.saveAttachment = _exports.deleteAttachment = void 0;
  const saveAttachment = _torusoftApiAdapter.default.saveAttachment;
  _exports.saveAttachment = saveAttachment;
  const deleteAttachment = _torusoftApiAdapter.default.deleteAttachment;
  _exports.deleteAttachment = deleteAttachment;
});