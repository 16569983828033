define("ember-torusoft-api-adapter/transforms/attachments", ["exports", "@ember-data/serializer/transform", "@ember/array", "@ember/polyfills", "@ember/object", "@ember/utils", "ember-data"], function (_exports, _transform, _array, _polyfills, _object, _utils, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // Adapted from https://github.com/pouchdb-community/ember-pouch
  const keys = Object.keys || _polyfills.keys;

  class AttachmentsTransform extends _transform.default {
    deserialize(serialized) {
      if ((0, _utils.isNone)(serialized)) {
        return [];
      }

      let result = Object.keys(serialized).map(function (attachmentName) {
        let attachment = serialized[attachmentName];
        return _object.default.create({
          name: attachmentName,
          content_type: attachment.content_type,
          data: attachment.data,
          stub: attachment.stub,
          length: attachment.length,
          digest: attachment.digest
        });
      });
      return result;
    }

    serialize(deserialized) {
      if (!(0, _array.isArray)(deserialized)) {
        return null;
      }

      let result = deserialized.reduce(function (acc, attachment) {
        const serialized = {
          content_type: (0, _object.get)(attachment, 'content_type')
        };

        if ((0, _object.get)(attachment, 'stub')) {
          serialized.stub = true;
          serialized.length = (0, _object.get)(attachment, 'length');
          serialized.digest = (0, _object.get)(attachment, 'digest');
        } else {
          serialized.data = (0, _object.get)(attachment, 'data');
          serialized.length = (0, _object.get)(attachment, 'length');
        }

        acc[(0, _object.get)(attachment, 'name')] = serialized;
        return acc;
      }, {});
      return result;
    }

  }

  _exports.default = AttachmentsTransform;
});