define("ember-torusoft-signin/components/forms/parts/bodies/forgot-password", ["exports", "@glimmer/component", "@ember/service", "@ember/object", "@glimmer/tracking", "ember-torusoft-signin/utils/user-password-reset"], function (_exports, _component, _service, _object, _tracking, _userPasswordReset) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    {{#if (has-block)}}
    {{yield this}}
  {{else}}
    <div class="form-group">
        <label for="identifier-input">{{this.inputLabel}}</label>
        <Input class="form-control" @id="identifier-input" @required={{true}} type={{this.inputType}} @value={{this.userIdentifier}} @autofocus="true" />
    </div>
    <button disabled={{not this.userIdentifier}} {{on 'click' this.onClickForgotPassword}} type="button" class="w-100 btn btn-success">{{this.submitPasswordResetButtonLabel}}</button>
  {{/if}}
  */
  {
    id: "PKRA692e",
    block: "[[[41,[48,[30,1]],[[[1,\"  \"],[18,1,[[30,0]]],[1,\"\\n\"]],[]],[[[1,\"  \"],[10,0],[14,0,\"form-group\"],[12],[1,\"\\n      \"],[10,\"label\"],[14,\"for\",\"identifier-input\"],[12],[1,[30,0,[\"inputLabel\"]]],[13],[1,\"\\n      \"],[8,[39,3],[[24,0,\"form-control\"],[16,4,[30,0,[\"inputType\"]]]],[[\"@id\",\"@required\",\"@value\",\"@autofocus\"],[\"identifier-input\",true,[30,0,[\"userIdentifier\"]],\"true\"]],null],[1,\"\\n  \"],[13],[1,\"\\n  \"],[11,\"button\"],[16,\"disabled\",[28,[37,4],[[30,0,[\"userIdentifier\"]]],null]],[24,0,\"w-100 btn btn-success\"],[24,4,\"button\"],[4,[38,5],[\"click\",[30,0,[\"onClickForgotPassword\"]]],null],[12],[1,[30,0,[\"submitPasswordResetButtonLabel\"]]],[13],[1,\"\\n\"]],[]]]],[\"&default\"],false,[\"if\",\"has-block\",\"yield\",\"input\",\"not\",\"on\"]]",
    moduleName: "ember-torusoft-signin/components/forms/parts/bodies/forgot-password.hbs",
    isStrictMode: false
  });

  let FormsPartsBodiesForgotPasswordComponent = (_class = class FormsPartsBodiesForgotPasswordComponent extends _component.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "signin", _descriptor, this);

      _initializerDefineProperty(this, "userIdentifier", _descriptor2, this);

      _initializerDefineProperty(this, "isProcessingRequest", _descriptor3, this);

      _initializerDefineProperty(this, "canSubmitMultipleTimes", _descriptor4, this);

      _initializerDefineProperty(this, "hasSubmittedRequest", _descriptor5, this);
    }

    get inputLabel() {
      return this.args.usernameInputLabel ? this.args.usernameInputLabel : this.signin.forgotPasswordUsernameInputLabel;
    }

    get inputType() {
      return this.args.usernameInputType ? this.args.usernameInputType : this.signin.forgotPasswordUsernameInputType;
    }

    get submitPasswordResetButtonLabel() {
      return this.args.submitPasswordResetButtonLabel ? this.args.submitPasswordResetButtonLabel : this.signin.forgotPasswordSubmitPasswordResetButtonLabel;
    }

    get requestOptions() {
      return this.args.passwordResetRequestOptions ? this.args.passwordResetRequestOptions : {
        headers: this.signin.requestHeaders
      };
    }

    get allowMultipleSubmissions() {
      return this.args.canSubmitMultipleRequests === true || this.args.canSubmitMultipleRequests === undefined || this.args.canSubmitMultipleRequests === null;
    }

    async onClickForgotPassword(submitEvent) {
      submitEvent.preventDefault();

      if (this.allowMultipleSubmissions === false && this.hasSubmittedRequest === true) {
        return;
      }

      let hookResponse = true;
      this.hasSubmittedRequest = true;

      if (this.args.onBeforeClickPasswordReset) {
        hookResponse = await this.args.onBeforeClickPasswordReset(this.userIdentifier, submitEvent, this);
      }

      if (hookResponse && this.args.onClickPasswordReset) {
        hookResponse = await this.args.onClickPasswordReset(hookResponse, this.userIdentifier, submitEvent, this);
      } else {
        hookResponse = await this._onClickPasswordReset(hookResponse, submitEvent);
      }

      if (hookResponse && this.args.onAfterClickPasswordReset) {
        hookResponse = await this.args.onAfterClickPasswordReset(hookResponse, this.userIdentifier, submitEvent, this);
      }

      return hookResponse;
    } // default implementation for password reset


    async _onClickPasswordReset(hookResponse, submitEvent) {
      submitEvent.srcElement.disabled = true;
      const postData = {
        id: this.userIdentifier,
        email: this.userIdentifier
      };

      if (this.args.forceDelay) {
        await new Promise(resolve => setTimeout(() => resolve(), this.args.forceDelay));
      }

      try {
        if (this.args.debugResponseSuccess) {
          return {
            'message': 'DEBUG: SIMULATING SUCCESSFUL SERVER RESPONSE'
          };
        } else if (this.args.debugResponseFailure) {
          return {
            'errors': [{
              detail: 'DEBUG: SIMULATING FAILED SERVER RESPONSE'
            }]
          };
        } else {
          return await (0, _userPasswordReset.requestPasswordReset)(this, postData, this.requestOptions);
        }
      } catch (errorObject) {
        return errorObject;
      } finally {
        if (this.allowMultipleSubmissions === true || this.hasSubmittedRequest !== true) {
          setTimeout(() => {
            submitEvent.srcElement.disabled = false;
          }, 2000);
        }
      }
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "signin", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "userIdentifier", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return "";
    }
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "isProcessingRequest", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "canSubmitMultipleTimes", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "hasSubmittedRequest", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "onClickForgotPassword", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "onClickForgotPassword"), _class.prototype)), _class);
  _exports.default = FormsPartsBodiesForgotPasswordComponent;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, FormsPartsBodiesForgotPasswordComponent);
});