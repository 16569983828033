define("ember-torusoft-signin/components/forms/parts/bodies/activate", ["exports", "@glimmer/component", "@ember/object", "@ember/service", "@glimmer/tracking", "ember-torusoft-signin/utils/user-account-activation"], function (_exports, _component, _object, _service, _tracking, _userAccountActivation) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class, _descriptor, _descriptor2, _descriptor3, _descriptor4;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    {{#if (has-block)}}
    {{yield this}}
  {{else}}
    {{#if @showAccountIdentifierInput}}
      <div class="form-group">
          <label for="identifier-input">{{this.inputLabel}}</label>
          <Input disabled={{not @isAccountIdentifierEditable}} class="form-control" @id="identifier-input" @required={{true}} type={{this.inputType}} @value={{this.userIdentifier}} @autofocus="true" />
      </div>
    {{/if}}
  
    <div class="form-group">
        <label for="password">{{this.passwordLabel}}</label>
        <Input class="form-control" @id="password" @required={{true}} type="password" @value={{this.password}} @autofocus="true" />
    </div>
    <div class="form-group">
        <label for="confirm-password">{{this.confirmPasswordLabel}}</label>
        <Input class="form-control" @id="confirm-password" @required={{true}} type="password" @value={{this.confirmedPassword}} @autofocus="true" />
    </div>
  
    <button disabled={{not this.isInputDataValid}} {{on 'click' this.onClickActivateAccount}} type="button" class="w-100 btn btn-success">{{this.submitActivateAccountButtonLabel}}</button>
  {{/if}}
  */
  {
    id: "b6SAXcJ9",
    block: "[[[41,[48,[30,3]],[[[1,\"  \"],[18,3,[[30,0]]],[1,\"\\n\"]],[]],[[[41,[30,1],[[[1,\"    \"],[10,0],[14,0,\"form-group\"],[12],[1,\"\\n        \"],[10,\"label\"],[14,\"for\",\"identifier-input\"],[12],[1,[30,0,[\"inputLabel\"]]],[13],[1,\"\\n        \"],[8,[39,3],[[16,\"disabled\",[28,[37,4],[[30,2]],null]],[24,0,\"form-control\"],[16,4,[30,0,[\"inputType\"]]]],[[\"@id\",\"@required\",\"@value\",\"@autofocus\"],[\"identifier-input\",true,[30,0,[\"userIdentifier\"]],\"true\"]],null],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]],null],[1,\"\\n  \"],[10,0],[14,0,\"form-group\"],[12],[1,\"\\n      \"],[10,\"label\"],[14,\"for\",\"password\"],[12],[1,[30,0,[\"passwordLabel\"]]],[13],[1,\"\\n      \"],[8,[39,3],[[24,0,\"form-control\"],[24,4,\"password\"]],[[\"@id\",\"@required\",\"@value\",\"@autofocus\"],[\"password\",true,[30,0,[\"password\"]],\"true\"]],null],[1,\"\\n  \"],[13],[1,\"\\n  \"],[10,0],[14,0,\"form-group\"],[12],[1,\"\\n      \"],[10,\"label\"],[14,\"for\",\"confirm-password\"],[12],[1,[30,0,[\"confirmPasswordLabel\"]]],[13],[1,\"\\n      \"],[8,[39,3],[[24,0,\"form-control\"],[24,4,\"password\"]],[[\"@id\",\"@required\",\"@value\",\"@autofocus\"],[\"confirm-password\",true,[30,0,[\"confirmedPassword\"]],\"true\"]],null],[1,\"\\n  \"],[13],[1,\"\\n\\n  \"],[11,\"button\"],[16,\"disabled\",[28,[37,4],[[30,0,[\"isInputDataValid\"]]],null]],[24,0,\"w-100 btn btn-success\"],[24,4,\"button\"],[4,[38,5],[\"click\",[30,0,[\"onClickActivateAccount\"]]],null],[12],[1,[30,0,[\"submitActivateAccountButtonLabel\"]]],[13],[1,\"\\n\"]],[]]]],[\"@showAccountIdentifierInput\",\"@isAccountIdentifierEditable\",\"&default\"],false,[\"if\",\"has-block\",\"yield\",\"input\",\"not\",\"on\"]]",
    moduleName: "ember-torusoft-signin/components/forms/parts/bodies/activate.hbs",
    isStrictMode: false
  });

  let FormsPartsBodiesActivateComponent = (_class = class FormsPartsBodiesActivateComponent extends _component.default {
    constructor() {
      super(...arguments);

      _defineProperty(this, "_passwordCriteria", /^(?=.*[A-Z])(?=.*[!@#$&*])(?=.*[0-9])(?=.*[a-z].*[a-z].*[a-z]).{8,}$/);

      _initializerDefineProperty(this, "signin", _descriptor, this);

      _initializerDefineProperty(this, "userIdentifier", _descriptor2, this);

      _initializerDefineProperty(this, "password", _descriptor3, this);

      _initializerDefineProperty(this, "confirmedPassword", _descriptor4, this);
    }

    get inputLabel() {
      return this.args.usernameInputLabel ? this.args.usernameInputLabel : this.signin.activateAccountUsernameInputLabel;
    }

    get inputType() {
      return this.args.usernameInputType ? this.args.usernameInputType : this.signin.activateAccountUsernameInputType;
    }

    get submitActivateAccountButtonLabel() {
      return this.args.submitActivateAccountButtonLabel ? this.args.submitActivateAccountButtonLabel : this.signin.activateAccountSubmitActivateAccountButtonLabel;
    }

    get passwordLabel() {
      return this.args.passwordLabel ? this.args.passwordLabel : this.signin.activateAccountPasswordInputLabel;
    }

    get confirmPasswordLabel() {
      return this.args.confirmPasswordLabel ? this.args.confirmPasswordLabel : this.signin.activateAccountConfirmPasswordInputLabel;
    }

    get requestOptions() {
      return this.args.activationRequestOptions ? this.args.activationRequestOptions : {
        headers: this.signin.requestHeaders
      };
    }

    get allowMultipleSubmissions() {
      return this.args.canSubmitMultipleRequests === true || this.args.canSubmitMultipleRequests === undefined || this.args.canSubmitMultipleRequests === null;
    }

    get isInputDataValid() {
      switch (true) {
        case this.args.accountIdentifier == null:
        case this.args.accountIdentifier == undefined:
        case this.args.accountIdentifier == "":
        case this.password != this.confirmedPassword:
        case this.password == "":
        case this.password == null:
        case this.password == undefined:
        case this.password.match(this._passwordCriteria) === null:
          return false;
      }

      return true;
    }

    async onClickActivateAccount(submitEvent) {
      submitEvent.preventDefault();

      if (this.allowMultipleSubmissions === false && this.hasSubmittedRequest === true) {
        return;
      }

      let hookResponse = true;
      this.hasSubmittedRequest = true;

      if (this.args.onBeforeClickActivateAccount) {
        hookResponse = await this.args.onBeforeClickActivateAccount(this.userIdentifier, submitEvent, this);
      }

      if (hookResponse && this.args.onClickActivateAccount) {
        hookResponse = await this.args.onClickActivateAccount(hookResponse, this.userIdentifier, submitEvent, this);
      } else {
        hookResponse = await this._onClickActivateAccount(hookResponse, submitEvent);
      }

      if (hookResponse && this.args.onAfterClickActivateAccount) {
        hookResponse = await this.args.onAfterClickActivateAccount(hookResponse, {
          userIdentifier: this.userIdentifier,
          password: this.password
        }, submitEvent, this);
      }

      return hookResponse;
    } // default implementation for account activation


    async _onClickActivateAccount(hookResponse, submitEvent) {
      submitEvent.srcElement.disabled = true;
      const postData = {
        id: this.userIdentifier,
        email: this.userIdentifier,
        activationToken: this.args.activationToken,
        password: this.password,
        confirmedPassword: this.confirmedPassword
      };

      if (this.args.forceDelay) {
        await new Promise(resolve => setTimeout(() => resolve(), this.args.forceDelay));
      }

      try {
        if (this.args.debugResponseSuccess) {
          return {
            'message': 'DEBUG: SIMULATING SUCCESSFUL SERVER RESPONSE'
          };
        } else if (this.args.debugResponseFailure) {
          return {
            'errors': [{
              detail: 'DEBUG: SIMULATING FAILED SERVER RESPONSE'
            }]
          };
        } else {
          return await (0, _userAccountActivation.sendActivationRequest)(this, postData, this.requestOptions);
        }
      } catch (errorObject) {
        return errorObject;
      } finally {
        if (this.allowMultipleSubmissions === true || this.hasSubmittedRequest !== true) {
          setTimeout(() => {
            submitEvent.srcElement.disabled = false;
          }, 2000);
        }
      }
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "signin", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "userIdentifier", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return this.args.accountIdentifier;
    }
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "password", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return "";
    }
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "confirmedPassword", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return "";
    }
  }), _applyDecoratedDescriptor(_class.prototype, "onClickActivateAccount", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "onClickActivateAccount"), _class.prototype)), _class);
  _exports.default = FormsPartsBodiesActivateComponent;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, FormsPartsBodiesActivateComponent);
});