define("ember-torusoft-signin/components/forms/parts/bodies/login", ["exports", "@glimmer/component", "@ember/object", "@ember/service", "@glimmer/tracking"], function (_exports, _component, _object, _service, _tracking) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class, _descriptor, _descriptor2, _descriptor3, _descriptor4;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    {{#if (has-block)}}
    {{yield this}}
  {{else}}
    <div class="input-group input-group-lg my-1">
      <div class="input-group-prepend">
        <div class="input-group-text sign-in-prepend"><FaIcon @icon="user" /></div>
      </div>
      <Input @value={{this.userIdentifier}} @type={{this.inputType}} @class="form-control" @placeholder={{this.inputLabel.userIdentifier}} required={{true}} autofocus={{true}} />
    </div>
    <div class="input-group input-group-lg  mb-2">
      <div class="input-group-prepend">
        <div class="input-group-text sign-in-prepend"><FaIcon @icon="key" /></div>
      </div>
      <Input @value={{this.password}} @type="password" @class="form-control" @placeholder={{this.inputLabel.password}} @required={{true}} />
    </div>
    <button disabled={{not this.isInputDataValid}} {{on 'click' this.onClickLogin}} type="button" class="w-100 btn btn-success my-2">{{this.signinButtonLabel}}</button>
  {{/if}}
  */
  {
    id: "g+D3xfci",
    block: "[[[41,[48,[30,1]],[[[1,\"  \"],[18,1,[[30,0]]],[1,\"\\n\"]],[]],[[[1,\"  \"],[10,0],[14,0,\"input-group input-group-lg my-1\"],[12],[1,\"\\n    \"],[10,0],[14,0,\"input-group-prepend\"],[12],[1,\"\\n      \"],[10,0],[14,0,\"input-group-text sign-in-prepend\"],[12],[8,[39,3],null,[[\"@icon\"],[\"user\"]],null],[13],[1,\"\\n    \"],[13],[1,\"\\n    \"],[8,[39,4],[[16,\"required\",true],[16,\"autofocus\",true]],[[\"@value\",\"@type\",\"@class\",\"@placeholder\"],[[30,0,[\"userIdentifier\"]],[30,0,[\"inputType\"]],\"form-control\",[30,0,[\"inputLabel\",\"userIdentifier\"]]]],null],[1,\"\\n  \"],[13],[1,\"\\n  \"],[10,0],[14,0,\"input-group input-group-lg  mb-2\"],[12],[1,\"\\n    \"],[10,0],[14,0,\"input-group-prepend\"],[12],[1,\"\\n      \"],[10,0],[14,0,\"input-group-text sign-in-prepend\"],[12],[8,[39,3],null,[[\"@icon\"],[\"key\"]],null],[13],[1,\"\\n    \"],[13],[1,\"\\n    \"],[8,[39,4],null,[[\"@value\",\"@type\",\"@class\",\"@placeholder\",\"@required\"],[[30,0,[\"password\"]],\"password\",\"form-control\",[30,0,[\"inputLabel\",\"password\"]],true]],null],[1,\"\\n  \"],[13],[1,\"\\n  \"],[11,\"button\"],[16,\"disabled\",[28,[37,5],[[30,0,[\"isInputDataValid\"]]],null]],[24,0,\"w-100 btn btn-success my-2\"],[24,4,\"button\"],[4,[38,6],[\"click\",[30,0,[\"onClickLogin\"]]],null],[12],[1,[30,0,[\"signinButtonLabel\"]]],[13],[1,\"\\n\"]],[]]]],[\"&default\"],false,[\"if\",\"has-block\",\"yield\",\"fa-icon\",\"input\",\"not\",\"on\"]]",
    moduleName: "ember-torusoft-signin/components/forms/parts/bodies/login.hbs",
    isStrictMode: false
  });

  let FormsPartsBodiesLoginComponent = (_class = class FormsPartsBodiesLoginComponent extends _component.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "signin", _descriptor, this);

      _initializerDefineProperty(this, "session", _descriptor2, this);

      _initializerDefineProperty(this, "userIdentifier", _descriptor3, this);

      _initializerDefineProperty(this, "password", _descriptor4, this);

      _defineProperty(this, "_passwordCriteria", /^(?=.*[A-Z])(?=.*[!@#$&*])(?=.*[0-9])(?=.*[a-z].*[a-z].*[a-z]).{8,}$/);
    }

    get passwordLabel() {
      return this.args.passwordLabel ? this.args.passwordLabel : this.signin.loginPasswordInputLabel;
    }

    get allowMultipleSubmissions() {
      return this.args.canSubmitMultipleRequests === true || this.args.canSubmitMultipleRequests === undefined || this.args.canSubmitMultipleRequests === null;
    }

    get signinButtonLabel() {
      return this.args.signinButtonLabel ? this.args.signinButtonLabel : this.signin.loginButtonLabel;
    }

    get isInputDataValid() {
      switch (true) {
        case this.userIdentifier == null:
        case this.userIdentifier == undefined:
        case this.userIdentifier == "":
          return false;

        case this.args.skipPasswordValidation === true:
          return true;

        case this.password == "":
        case this.password == null:
        case this.password == undefined:
        case this.password.match(this._passwordCriteria) === null:
          return false;
      }

      return true;
    }

    async onClickLogin(submitEvent) {
      submitEvent.preventDefault();

      if (this.allowMultipleSubmissions === false && this.hasSubmittedRequest === true) {
        return;
      }

      let hookResponse = true;
      this.hasSubmittedRequest = true;

      if (this.args.onBeforeClickLogin) {
        hookResponse = await this.args.onBeforeClickLogin(this.userIdentifier, submitEvent, this);
      }

      if (hookResponse && this.args.onClickLogin) {
        hookResponse = await this.args.onClickLogin(hookResponse, this.userIdentifier, submitEvent, this);
      } else {
        hookResponse = await this._onClickLogin(hookResponse, submitEvent);
      }

      if (hookResponse && this.args.onAfterClickLogin) {
        hookResponse = await this.args.onAfterClickLogin(hookResponse, {
          userIdentifier: this.userIdentifier,
          password: this.password
        }, submitEvent, this);
      }

      return hookResponse;
    }

    async _onClickLogin(hookResponse, submitEvent) {
      submitEvent.srcElement.disabled = true;

      if (this.args.forceDelay) {
        await new Promise(resolve => setTimeout(() => resolve(), this.args.forceDelay));
      }

      try {
        if (this.args.debugResponseSuccess) {
          return {
            'message': 'DEBUG: SIMULATING SUCCESSFUL SERVER RESPONSE'
          };
        } else if (this.args.debugResponseFailure) {
          return {
            'errors': [{
              detail: 'DEBUG: SIMULATING FAILED SERVER RESPONSE'
            }]
          };
        } else {
          let result = await this.session.authenticate("authenticator:torusoftapi", {
            username: this.userIdentifier,
            password: this.password
          });
          console.warn(result);
          return {
            ok: true
          };
        }
      } catch (errorObject) {
        return errorObject;
      } finally {
        if (this.allowMultipleSubmissions === true || this.hasSubmittedRequest !== true) {
          setTimeout(() => {
            submitEvent.srcElement.disabled = false;
          }, 2000);
        }
      }
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "signin", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "session", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "userIdentifier", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return this.args.accountIdentifier;
    }
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "password", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return "";
    }
  }), _applyDecoratedDescriptor(_class.prototype, "onClickLogin", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "onClickLogin"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "_onClickLogin", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "_onClickLogin"), _class.prototype)), _class);
  _exports.default = FormsPartsBodiesLoginComponent;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, FormsPartsBodiesLoginComponent);
});