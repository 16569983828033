define("ember-torusoft-signin/templates/reset-password", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "+cZPJ9ch",
    "block": "[[[10,0],[14,0,\"container p-5 mt-5\"],[12],[1,\"\\n  \"],[8,[39,0],null,[[\"@showServerResponse\",\"@showServerMessage\",\"@showAccountIdentifierInput\",\"@resetToken\",\"@accountIdentifier\",\"@passwordResetRequestOptions\",\"@debugResponseSuccess\",\"@debugResponseFailure\"],[true,true,false,[30,0,[\"resetToken\"]],[30,0,[\"accountIdentifier\"]],null,false,false]],null],[1,\"\\n\"],[13]],[],false,[\"forms/password-reset\"]]",
    "moduleName": "ember-torusoft-signin/templates/reset-password.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});