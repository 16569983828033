define("ember-torusoft-api-adapter/adapters/torusoft-api-adapter", ["exports", "@ember-data/adapter/rest", "@ember-data/adapter/error", "@ember/object", "@ember/application", "@ember/string", "ember-inflector", "@glimmer/tracking"], function (_exports, _rest, _error, _object, _application, _string, _emberInflector, _tracking) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class, _descriptor, _descriptor2;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let TorusoftApiAdapter = (_class = class TorusoftApiAdapter extends _rest.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "namespace", _descriptor, this);

      _initializerDefineProperty(this, "host", _descriptor2, this);

      _defineProperty(this, "appConfig", undefined);

      this.appConfig = (0, _application.getOwner)(this).resolveRegistration('config:environment');

      if (this.appConfig.apiServer) {
        this.namespace = this.appConfig.apiServer.namespace ? this.appConfig.apiServer.namespace : 'api';
        this.host = this.appConfig.apiServer.host ? this.appConfig.apiServer.host : 'http://localhost:5000'; // default flask development server
      }
    } // Override this function to customize behaviour of header generation for each request
    // sent to api server.  By default, headers specified in configuration will be sent if
    // they exist, otherwise, a simple set of accept and credential headers are sent


    get headers() {
      if (this.appConfig.apiServer && this.appConfig.apiServer.headers) {
        return this.appConfig.apiServer.headers;
      } else {
        return {
          Accept: "application/json",
          credentials: 'include'
        };
      }
    }

    get apiRootPath() {
      return `${this.host}/${this.namespace}`;
    }

    pathForType(modelName) {
      return (0, _string.dasherize)((0, _emberInflector.pluralize)(modelName));
    }

    handleResponse(status, headers, payload) {
      super.handleResponse(...arguments);
      let data = typeof payload == "string" ? JSON.parse(payload) : payload;

      if (data && data.errors) {
        let unauthorizedErrors = data.errors.filter(error => error.status == 401);

        if (unauthorizedErrors.length > 0) {
          throw new _error.UnauthorizedError(data.errors);
        } else {
          throw new _error.InvalidError(data.errors);
        }
      } else {
        return payload;
      }
    }

    async deleteRecord(store, type, record) {
      const response = await super.deleteRecord(store, type, record);
      const modelName = (0, _string.camelize)(type.modelName);

      if (modelName in response && 'deleted' in response[modelName]) {
        if (response[modelName].deleted == true) {
          return null;
        }
      }

      return response;
    }

    async findRecord(store, type, id, snapshot) {
      return super.findRecord(store, type, id, snapshot);
    }

    async findAll(store, type, neverSet, snapshotRecordArray) {
      return super.findAll(...arguments);
    }

    async findMany(store, type, ids) {
      console.log("STUB: torusoft-api-adapter::findMany", type, ids);
      return super.findMany(...arguments);
    }

    async findHasMany(store, record, link, rel) {
      console.log("STUB: torusoft-api-adapter::findHasMany", record, link, rel);
      return super.findHasMany(...arguments);
    }

    query(store, type, query) {
      let queryParams = {
        'filter': encodeURIComponent(JSON.stringify(query))
      };
      return super.query(store, type, queryParams);
    }

    async queryRecord(store, type, query) {
      const modelName = (0, _string.dasherize)((0, _string.camelize)(type.modelName));
      let queryParams = {
        'filter': encodeURIComponent(JSON.stringify(query))
      };
      let queryResult = await super.queryRecord(store, type, queryParams);

      if (queryResult[(0, _emberInflector.pluralize)(modelName)].length > 0) {
        queryResult[modelName] = queryResult[(0, _emberInflector.pluralize)(modelName)][0];
      } else {
        queryResult[modelName] = null;
      }

      delete queryResult[(0, _emberInflector.pluralize)(modelName)];
      return queryResult;
    }

    async createRecord(store, type, snapshot) {
      await this.saveRelationships(type, snapshot);
      return super.createRecord(...arguments);
    }

    async updateRecord(store, type, snapshot) {
      await this.saveRelationships(type, snapshot);
      return super.updateRecord(...arguments);
    }

    saveRelationships(type, snapshot) {
      let savePromises = [];
      type.eachRelationship((attributeName, relationshipDefinition) => {
        if (relationshipDefinition.kind === "hasMany") {
          this.saveHasManyRelationships(attributeName, snapshot.record).map(record => savePromises.push(record));
        } else if (relationshipDefinition.kind === "belongsTo") {
          savePromises.push(this.saveBelongsToRelationship(snapshot.record.belongsTo(attributeName).value()));
        }
      });
      return Promise.all(savePromises);
    }

    saveBelongsToRelationship(relatedRecord) {
      if (!relatedRecord) {
        return Promise.resolve(relatedRecord);
      }

      let emberDataTrackerChangesDetected = 'modelChanges' in relatedRecord && relatedRecord.modelChanges && Object.keys(relatedRecord.modelChanges()).length > 0;
      let emberDataNativeChangesDetected = relatedRecord.hasDirtyAttributes || relatedRecord.isNew;
      return emberDataTrackerChangesDetected || emberDataNativeChangesDetected ? relatedRecord.save() : Promise.resolve(relatedRecord);
    }

    saveHasManyRelationships(attributeName, snapshotRecord) {
      let relatedRecords = snapshotRecord.hasMany(attributeName).value();
      if (!relatedRecords) return [];
      return relatedRecords.map(relatedRecord => {
        if (!relatedRecord) {
          return Promise.resolve(relatedRecord);
        }

        let emberDataTrackerChangesDetected = 'modelChanges' in relatedRecord && relatedRecord.modelChanges && Object.keys(relatedRecord.modelChanges()).length > 0;
        let emberDataNativeChangesDetected = relatedRecord.hasDirtyAttributes || relatedRecord.isNew;
        return emberDataTrackerChangesDetected || emberDataNativeChangesDetected ? relatedRecord.save() : Promise.resolve(relatedRecord);
      });
    }

    static async saveAttachment(model, attribute, attachment, options) {
      if (model.isNew) {
        throw new _error.AbortError([{
          "detail": "New records must be saved before an attachment can be added."
        }], "Attachment could not be saved.");
      }

      if (!(attribute in model)) {
        throw TypeError(`'${attribute}' is not an attribute of the provided model. This is likely a typo.`);
      }

      const headers = {
        'If-Match': model.rev,
        'Content-type': attachment.type,
        'Custom-Torusoft-Attachment-Key': attribute
      };
      const adapterInstance = model.store.adapterFor(model.constructor.modelName);
      const pluralizedModelName = (0, _emberInflector.pluralize)(model.constructor.modelName);
      const url = `${adapterInstance.apiRootPath}/${pluralizedModelName}/${model.id}/attachments/${attachment.name}`;
      const fetch_result = await (await fetch(url, {
        method: 'POST',
        headers: headers,
        credentials: 'include',
        body: attachment.blob
      })).json();

      if (attribute in fetch_result) {
        let payload = {};
        payload[pluralizedModelName] = fetch_result;
        model.store.pushPayload(model.constructor.modelName, payload);

        if ('saveTrackerChanges' in model) {
          // this is required for ember-data-change-tracker.
          // see: https://github.com/danielspaniel/ember-data-change-tracker#known-issues
          model.saveTrackerChanges();
        }
      }
    }

    static async deleteAttachment(model, attribute, attachment, options) {
      // console.log(attachment)
      // return
      if (model.isNew) {
        throw new _error.AbortError([{
          "detail": "New records must be saved before an attachment can be deleted."
        }], "Attachment could not be deleted.");
      }

      if (!(attribute in model)) {
        throw TypeError(`'${attribute}' is not an attribue of the provided model. This is likely a typo.`);
      }

      const headers = {
        // 'If-Match': model.rev,
        // 'Content-type': attachment.type,
        'Custom-Torusoft-Attachment-Key': attribute
      };
      const adapterInstance = model.store.adapterFor(model.constructor.modelName);
      const pluralizedModelName = (0, _emberInflector.pluralize)(model.constructor.modelName);
      const url = `${adapterInstance.apiRootPath}/${pluralizedModelName}/${model.id}/attachments/${attachment.name}`;
      const fetch_result = await (await fetch(url, {
        method: 'DELETE',
        headers: headers,
        credentials: 'include'
      })).json();

      if (attribute in fetch_result) {
        let payload = {};
        payload[pluralizedModelName] = fetch_result;
        model.store.pushPayload(model.constructor.modelName, payload);

        if ('saveTrackerChanges' in model) {
          // this is required for ember-data-change-tracker.
          // see: https://github.com/danielspaniel/ember-data-change-tracker#known-issues
          model.saveTrackerChanges();
        }
      }
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "namespace", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return undefined;
    }
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "host", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return undefined;
    }
  })), _class);
  _exports.default = TorusoftApiAdapter;
});