define("ember-torusoft-signin/templates/sign-in", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "I5Go6L3/",
    "block": "[[[10,0],[14,0,\"container p-5 mt-5\"],[12],[1,\"\\n  \"],[8,[39,0],null,[[\"@showServerResponse\",\"@passwordResetRoute\",\"@skipPasswordValidation\"],[true,\"forgot-password\",true]],null],[1,\"\\n\"],[13]],[],false,[\"forms/login\"]]",
    "moduleName": "ember-torusoft-signin/templates/sign-in.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});