define("ember-pouch/transforms/attachment", ["exports", "@ember/utils", "ember-pouch/transforms/attachments"], function (_exports, _utils, _attachments) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _attachments.default.extend({
    deserialize: function (serialized) {
      return this._super(serialized).pop();
    },
    serialize: function (deserialized) {
      if ((0, _utils.isNone)(deserialized)) {
        return null;
      }

      return this._super([deserialized]);
    }
  });

  _exports.default = _default;
});