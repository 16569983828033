define("ember-toastr/services/toast", ["exports", "@ember/runloop", "@ember/service", "@ember/array"], function (_exports, _runloop, _service, _array) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  let proxyGenerator = function (name) {
    return function () {
      let msg = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';
      let title = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : '';
      let options = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
      let toasts = this.toasts;
      let toast;

      if (window && window.toastr) {
        toast = window.toastr[name](msg.toString(), title.toString(), options);

        if (toast) {
          toasts.pushObject(toast);
        }
      }

      return toast;
    };
  };

  var _default = _service.default.extend({
    success: proxyGenerator('success'),
    info: proxyGenerator('info'),
    warning: proxyGenerator('warning'),
    error: proxyGenerator('error'),

    init() {
      this._super(...arguments);

      this.toasts = (0, _array.A)([]); // Auto remove toasts when hidden

      if (window && window.toastr) {
        window.toastr.options = this.config.toastrOptions || this.defaultToastrOptions;
        window.toastr.options.onHidden = (0, _runloop.bind)(this, () => {
          let toasts = this.toasts;
          let notVisible = toasts.filter(item => !item.is(':visible'));
          toasts.removeObjects(notVisible);
        });
      }
    },

    clear(toastElement) {
      if (window && window.toastr) {
        window.toastr.clear(toastElement);

        if (toastElement) {
          this.toasts.removeObject(toastElement);
        } else {
          this.set('toasts', (0, _array.A)([]));
        }
      }
    },

    remove(toastElement) {
      if (toastElement) {
        this.toasts.removeObject(toastElement);
        toastElement.remove();
      } else {
        this.set('toasts', (0, _array.A)([]));
      }

      if (window && window.toastr) {
        window.toastr.remove(toastElement);
      }
    },

    willDestroy() {
      this._super(...arguments);

      this.remove();
    }

  });

  _exports.default = _default;
});