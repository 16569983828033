define("ember-torusoft-signin/templates/activate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "8Y0JqDv7",
    "block": "[[[10,0],[14,0,\"container p-5 mt-5\"],[12],[1,\"\\n  \"],[8,[39,0],null,[[\"@activationRequestOptions\",\"@showServerResponse\",\"@showServerMessage\",\"@allowEditingUserIdentifier\",\"@activationToken\",\"@accountIdentifier\",\"@showAccountIdentifierInput\",\"@isAccountIdentifierEditable\",\"@canSubmitMultipleRequests\",\"@debugResponseSuccess\",\"@debugResponseFailure\"],[null,true,true,false,[30,0,[\"activationToken\"]],[30,0,[\"accountIdentifier\"]],false,false,false,false,false]],null],[1,\"\\n\"],[13]],[],false,[\"forms/activate-account\"]]",
    "moduleName": "ember-torusoft-signin/templates/activate.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});