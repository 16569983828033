define("ember-torusoft-signin/templates/forgot-password", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "hli0TlsO",
    "block": "[[[10,0],[14,0,\"container p-5 mt-5\"],[12],[1,\"\\n  \"],[8,[39,0],null,[[\"@showServerResponse\",\"@showServerMessage\",\"@passwordResetRoute\",\"@canSubmitMultipleRequests\",\"@passwordResetRequestOptions\",\"@debugResponseSuccess\",\"@debugResponseFailure\"],[true,true,\"forgot-password\",false,null,false,false]],null],[1,\"\\n\"],[13]],[],false,[\"forms/forgot-password\"]]",
    "moduleName": "ember-torusoft-signin/templates/forgot-password.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});