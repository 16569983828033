define("ember-torusoft-api-adapter/serializers/application", ["exports", "@ember-data/serializer/rest", "@ember/object"], function (_exports, _rest, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // Adaptation of https://github.com/pouchdb-community/ember-pouch
  class ApplicationSerializer extends _rest.default {
    serializeAttribute(snapshot, json, key, attribute) {
      super.serializeAttribute(snapshot, json, key, attribute);

      if (this._isAttachment(attribute)) {
        // if provided, use the mapping provided by `attrs` in the serializer
        var payloadKey = this._getMappedKey(key, snapshot.type);

        if (payloadKey === key && this.keyForAttribute) {
          payloadKey = this.keyForAttribute(key, 'serialize');
        }

        const attachmentsKey = 'attachments' in json ? 'attachments' : '_attachments';
        json[attachmentsKey] = Object.assign({}, json[attachmentsKey] || {}, json[payloadKey]); // jshint ignore:line

        json[payloadKey] = Object.keys(json[payloadKey]).reduce((attr, fileName) => {
          attr[fileName] = Object.assign({}, json[payloadKey][fileName]); // jshint ignore:line

          delete attr[fileName].data;
          delete attr[fileName].content_type;
          return attr;
        }, {});
      }
    }

    serializeHasMany(snapshot, json, relationship) {
      if (this._shouldSaveRelationship(this, relationship)) {
        super.serializeHasMany(...arguments); // add the hasMany as an empty array if no relations are formed yet

        if (!json[relationship.key]) {
          json[relationship.key] = [];
        }
      }
    }

    extractAttributes(modelClass, resourceHash) {
      let attributes = super.extractAttributes(modelClass, resourceHash);
      let modelAttrs = modelClass.attributes;

      if (!resourceHash.deleted) {
        modelClass.eachTransformedAttribute(key => {
          if (this._isAttachment(modelAttrs.get(key))) {
            // put the corresponding _attachments entries from the response into the attribute
            if (!(key in attributes)) {
              throw TypeError(`'${key}' an attribute on the model, but it is not present in the data returned from the server.`);
            }

            let fileNames = Object.keys(attributes[key]);
            fileNames.forEach(fileName => {
              // ember-pouch saved data will have an 'attachments' key.
              // standard couchdb will be have an '_attachments' key
              if (resourceHash.attachments) {
                attributes[key][fileName] = resourceHash.attachments[fileName];
              } else {
                attributes[key][fileName] = resourceHash._attachments[fileName];
              }
            });
          }
        });
      }

      return attributes;
    }

    extractRelationships(modelClass) {
      let relationships = super.extractRelationships(...arguments);
      modelClass.eachRelationship((key, relationshipMeta) => {
        if (relationshipMeta.kind === 'hasMany' && !this._shouldSaveRelationship(this, relationshipMeta) && !!relationshipMeta.options.async) {
          // TODO: confirm this json structure is correct for lazy loading relationships
          // see relationships section at https://api.emberjs.com/ember-data/3.17/classes/RESTAdapter
          relationships[key] = {
            links: {
              related: key
            }
          };
        }
      });
      return relationships;
    }

    _isAttachment(attribute) {
      return ['attachment', 'attachments'].indexOf(attribute.type) !== -1;
    }

    _shouldSaveRelationship(container, relationship) {
      if (typeof relationship.options.save !== "undefined") return relationship.options.save;
      if (relationship.kind === 'belongsTo') return true;
      return true;
    }

  }

  _exports.default = ApplicationSerializer;
});