define("ember-torusoft-api-adapter/helpers/get-attachment-url", ["exports", "@ember/component/helper", "ember-inflector", "@ember/application"], function (_exports, _helper, _emberInflector, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _helper.helper)(function getAttachmentUrl(_ref
  /*, hash*/
  ) {
    let [model, attachmentName] = _ref;
    const appConfig = (0, _application.getOwner)(model).resolveRegistration('config:environment');
    const host = appConfig.apiServer.host;
    const namespace = appConfig.apiServer.namespace;
    const modelEndpoint = `${(0, _emberInflector.pluralize)(model.constructor.modelName)}/${model.id}`;
    return `${host}/${namespace}/${modelEndpoint}/attachments/${attachmentName}`;
  });

  _exports.default = _default;
});