define("ember-torusoft-signin/components/contextual-component", ["exports", "@ember/component/template-only"], function (_exports, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    {{yield}}
  */
  {
    id: "d0QUzlkH",
    block: "[[[18,1,null]],[\"&default\"],false,[\"yield\"]]",
    moduleName: "ember-torusoft-signin/components/contextual-component.hbs",
    isStrictMode: false
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());

  _exports.default = _default;
});